import { Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Home from './Pages/Home';
import Footer from './Components/Footer';
import Poi from './Pages/Poi';
import React from 'react';
import AllCategory from './Pages/AllCategory';
import AllCity from './Pages/City/AllCity'
import AllBlogs from './Pages/Blogs';
import AllPoi from './Pages/Poi/allPoi';
import AllDestination from './Pages/Destination';
import SingleCity from './Pages/City/singleCity';

function App({ initialData = {} }) {
  // Use useLocation to get the current path
  const location = useLocation();

  // Check if the current route is the 404 NotFound page
  const isNotFound = location.pathname === "/404" || location.pathname === "/notfound"; // Customize based on your routing logic
  const isPathMatched = location.pathname === '/' || location.pathname === '/category' || location.pathname === '/all-city' || location.pathname.startsWith('/en/place/') || location.pathname.startsWith('/ar/place/') || location.pathname === '/blogs' || location.pathname === '/all-poi';

  return (
    <>
      {/* Render NavBar only if not on NotFound page */}
      {isPathMatched && <NavBar />}
      <Routes>
        <Route path='/' element={<Home  initialData={{popular:initialData.Popular,poi:initialData.Attractions}}/>} />
        <Route path='/category' element={<AllCategory />} />
        <Route path='/all-city' element={<AllCity initialData={initialData} />} />
        <Route path='/en/place/:city/:url' element={<Poi  initialData={{poi:initialData.Poi,category:initialData.Category}}/>} />
        <Route path='/ar/place/:city/:url' element={<Poi  initialData={{poi:initialData.Poi,category:initialData.Category}}/>} />
        <Route path='/blogs' element={<AllBlogs/>}></Route>
        <Route path='/all-poi' element={<AllPoi/>}></Route>
        <Route path='/all-destination' element={<AllDestination/>}></Route>
      </Routes>
      {/* Render Footer only if not on NotFound page */}
      {isPathMatched && <Footer />}
    </>
  );
}

export default App;
