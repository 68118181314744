import PlaceIcon from '@mui/icons-material/Place';
import React from "react";

function PopularDestinationCard({ category,key }) {
  // Render the category data directly
  return (
    <div className="max-sm:w-[40%] lg:w-[20%] rounded-md text-center cursor-pointer flex-none" id="popular-destination-card" key={key}>
      <div id="popular-destination-card-image">
        <img
          src={category.image}
          alt={category.categoryName || "Category Image"}
          className="rounded-xl lg:w-[276px] lg:h-[350px] max-sm:w-[120px] max-sm:h-[158px]"
        />
      </div>
      <div className="relative text-center bottom-[60px]" id="popular-destination-card-text">
        <p className="text-white font-bold max-sm:text-xl lg:text-2xl">
          {category.categoryName}
        </p>
        <p className="text-white">
          <PlaceIcon /> {category.slug}
        </p>
      </div>
    </div>
  );
}

export default PopularDestinationCard;
