import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

 function AccordionUsage({qa}) {
    const [data,setData]=React.useState([])
    // React.useEffect(()=>{
    //     setData(qa)
    //     console.log(props)
    // },[props])
  return (
    <div>
        {qa?.map((i,key)=>{
            return(
            <Accordion key={i.key} sx={{ boxShadow: 'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className='font-normal text-[#454545] -ml-3'
        >
          {i.question}
        </AccordionSummary>
        <AccordionDetails
        className='font-normal text-[#454545] -ml-3'
        >
          {i.answer}
        </AccordionDetails>
      </Accordion>
            )
        })}
      
     
    
    </div>
  );
}
export default AccordionUsage
