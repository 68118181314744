import { useEffect, useState } from "react";
import { primary, url } from "../../AppConfig";
import React from "react";
import Cookies from "js-cookie"
function Category() {
  const [subCatgeory, setSubCategory] = useState([]);
  useEffect(() => {
    fetch(`${url}/all-sub-category`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        console.log(response);
        if (response.status === true) {
          setSubCategory(response.data);
        }
      });
  }, []);
  return (
    <>
      <div>
        <div>
          {Cookies.get("lang") === "ar" ? (
            <div className="flex flex-row-reverse lg:me-20 max-sm:me-5 mt-16">
              <div>
                <p className="text-xl">Immerse Yourself</p>
                <p
                  className="lg:text-4xl max-sm:text-2xl font-bold"
                  style={{ color: `${primary}` }}
                >
                  Trending Categories
                </p>
              </div>
              <div
                className="lg:w-[50%] max-sm:w-[30%]"
                style={{ borderBottom: "1px solid black" }}
              ></div>
            </div>
          ) : (
            <div className="flex lg:ms-20 max-sm:ms-5 mt-16">
              <div>
                <p className="text-xl">Immerse Yourself</p>
                <p
                  className="lg:text-4xl max-sm:text-2xl font-bold"
                  style={{ color: `${primary}` }}
                >
                  Trending Categories
                </p>
              </div>
              <div
                className="lg:w-[60%] max-sm:w-[30%]"
                style={{ borderBottom: "1px solid black" }}
              ></div>
            </div>
          )}
        </div>

        <div className=" max-sm:hidden">
          <div className="flex w-full px-20  mt-16 gap-8">
            <div className="">
              <div
                className="w-[662px] h-[420px] rounded-lg object-cover cursor-pointer"
                id="trending-cat-card"
              >
                <img
                  className="w-[662px] h-[420px] rounded-lg object-cover"
                  id="trending-cat-card-img"
                  src="https://english.cdn.zeenews.com/sites/default/files/2022/09/21/1093268-india-gate.jpg"
                  alt="Image 2"
                />
                <div
                  className="relative bottom-7 z-10 px-5 "
                  id="trending-cat-text"
                >
                  <h1 className="text-white font-semibold text-[25px]">
                    HII ROHIT SHARMA{" "}
                  </h1>
                  <p className="hidden paragraph text-white  text-[22px] mt-6">
                    Rising perpendicular and impregnable from a rocky hill that
                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                    of the most magnificent forts in…
                  </p>
                  <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                    Read More
                  </button>
                </div>
              </div>

            </div>
            <div className="">
              <div
                className="w-[662px] h-[420px] rounded-lg object-cover cursor-pointer"
                id="trending-cat-card"
              >
                <img
                  className="w-[662px] h-[420px] rounded-lg object-cover"
                  id="trending-cat-card-img"
                  src="https://english.cdn.zeenews.com/sites/default/files/2022/09/21/1093268-india-gate.jpg"
                  alt="Image 2"
                />
                <div
                  className="relative bottom-7 z-10 px-5 "
                  id="trending-cat-text"
                >
                  <h1 className="text-white font-semibold text-[25px]">
                    HII ROHIT SHARMA{" "}
                  </h1>
                  <p className="hidden paragraph text-white  text-[22px] mt-6">
                    Rising perpendicular and impregnable from a rocky hill that
                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                    of the most magnificent forts in…
                  </p>
                  <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                    Read More
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div className="flex flex-row-start mt-10 px-20 max-sm:me-5 ">
            <button className="border-2 border-black rounded-full    px-7 py-1 hover:bg-[#9747FF] hover:border-white hover:text-white ">View All</button>
          </div>
        </div>

        <div className="lg:hidden flex px-5 mt-5 overscroll-x-conatin overflow-x-scroll gap-5">
          <div className="w-[277px] h-[112.44px] flex-none">
            <img
              className="w-[277px] h-[112.44px] rounded-md"
              src="https://www.nationaloutdoorfurniture.com/files/Blog/the-importance-of-public-parks3.jpg"
            />
            <div
              className="relative bottom-5 z-10 px-3 "
              id="trending-cat-text"
            >
              <h1 className="text-white font-semibold text-[12px]">
                National Parks In India {" >"}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
