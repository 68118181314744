import { useEffect, useState } from "react";
import React from "react";
import AccordionUsage from "../../Components/Poi/Accordin";
import Slider from "../../Components/Poi/Slider";
import Description from "../../Components/Poi/Description";
import LanguageIcon from "@mui/icons-material/Language";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Link, useLocation, useParams } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import ImportantTips from "../../Components/Poi/ImportantTips";
import PopularDestination from "../../Components/PopularDestination";
import PopularDestinationCard from "../../Components/PopularDestination/PopularDestinationCard";
import { primary, url } from "../../AppConfig";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Lines from "../../Components/Shimmer/Lines";
import Boxes from "../../Components/Shimmer/Boxes";
import Cookies from "js-cookie"
import axios from "axios";
function Poi({initialData={}}) {
//   const [qa, setQa] = useState([]);
const [lang,setLang]=useState(Cookies.get("lang"))
console.log(initialData)
const { url } = useParams();
let poiId=url.split('-').pop()
const location = useLocation();
useEffect(()=>{
  if(location.pathname.includes("en")){
    Cookies.set('lang', "en", { expires: 7 })
    setLang("en")
  }else{
    Cookies.set('lang', "ar", { expires: 7 })
    setLang("ar")
  }
},[location])

console.log(poiId)
const [poi, setPoi] = useState(initialData.poi?.data || {});
  const [images, setImages] = useState(initialData.poi?.data?.images || []);
  const [load, setLoad] = useState(!!initialData.poi);
  const [category,setCategory]=useState(initialData.category?.data || [])
  
  useEffect(() => {
    // console.log("Params:", poiId); // Log params
    // console.log("Initial Data:", initialData); // Log initial data

    if (initialData.poi===undefined || initialData.category===undefined) {
      const fetchPoiData = async () => {
        try {
          const poiResponse = await axios.get(`http://45.90.220.36:8080/api/single-poi/${poiId}`);
          setPoi(poiResponse.data.data);
          console.log(poiResponse.data)
          setImages(poiResponse.data.data.images)
          const categoryResponse = await axios.get(`http://45.90.220.36:8080/api/all-category`);
          setCategory(categoryResponse.data.data);
          console.log(categoryResponse.data)
          setLoad(true);
        } catch (error) {
          console.error("Error fetching data on client-side navigation:", error);
          setLoad(false);
        }
      };

      fetchPoiData();
    } else {
      setLoad(true);
    }
  }, [poiId, poi, category,location]);

 
  // useEffect(() => {
  //   // setCategory(initialData.category.data)
  //   setPoi(initialData.poi.data)
  //   setImages(initialData.poi.data.images)
  //   setLoad(true)
   
  // }, [initialData]);

  // useEffect(() => {
  //   fetch(`${url}/single-poi/${poiId}`, {
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((response) => {
  //       if (response.status === true) {
  //          setPoi(response?.data);
  //          setImages(response.data?.images)
  //         console.log(response.data);
  //         setLoad(true)
  //       }
  //     });
  // }, [poiId]);
  // const lang = Cookies.get("lang") || "";

  return (
    <>
      <div className="px-5 lg:px-20 lg:mt-10 max-sm:mt-5">
        {load===false? 
        <Lines width={"50%"}/>
        :
      
        <>
        <div className="">
          <h1 className="lg:text-[70px]  font-medium">
            {lang === "ar" ? poi.ar_title : poi.title}
          </h1>

          <p className="mt-3 text-[20px] font-light">
            {lang === "ar" ? poi.ar_location : poi.location}
          </p>
        </div>
        <div className="flex flex-wrap gap-3 mt-3">
          {poi?.tags?.map((i) => (
            <div className="border font-normal border-[#A5A5A5] px-2 rounded-md">{i}</div>
          ))}
        </div>
        </>
}
        {/* Image Slider */}
        {load === false ? (
           <div className="flex justify-between flex-col lg:flex-row lg:gap-10 w-full mt-5 items-center">
            <div className="mt-10 lg:mt-0 lg:w-full w-full">
            <Boxes/>
            </div>
            <div className="flex justify-between flex-col lg:flex-row lg:gap-10 w-full mt-5 items-center">
            <Boxes/>
            
            </div>
           </div>
          
) : (
  lang === "ar" ? (
    <div className="flex justify-between flex-col lg:flex-row lg:gap-10 w-full mt-5 items-center">
      <div className="mt-10 lg:mt-0 lg:w-2/5 w-full">
        <p className="lg:text-4xl text-[36px] italic">
          ‘{poi.ar_blurb}’
        </p>
        <p className="mt-3 text-[20px]">
          {poi.ar_shortDescription}
        </p>
      </div>
      <div className="lg:w-[793px] lg:h-[459px] max-sm:w-[320px] max-sm:h-[190px]">
        <Slider images={images} />
      </div>
    </div>
  ) : (
    <div className="flex justify-between flex-col lg:flex-row lg:gap-10 w-full mt-5 items-center">
      <div className="lg:w-[793px] lg:h-[459px] max-sm:w-[320px] max-sm:h-[190px]">
        <Slider images={images} />
      </div>
      <div className="mt-10 lg:mt-0 lg:w-1/2 w-full">
        <p className="lg:text-4xl text-xl italic">
          ‘{poi.blurb}’
        </p>
        <p className="mt-3 text-lg">
          {poi.shortDescription}
        </p>
      </div>
    </div>
  )
)}

        {/* Important Things To Know */}
       {load===false ?
       <div className="mt-10">
   <Lines width={"100%"}/>
       </div>
    
       :
       <div className="">
       <div className=" font-semibold text-[25px] mt-[70px]">
        {lang === "ar" ? "أشياء مهمة يجب معرفتها:" : "Important things to know:"}
       </div>
       <div className="" >
         <AccordionUsage qa={poi?.thingsToKnow} />
       </div>
     </div>
       }
       

        {/* Description */}

        <div className="mt-10 ">
          <Description description={poi.description} ar_description={poi.ar_description}/>
        </div>

       

        {/* Addresss Part */}
        <div className="lg:flex lg:justify-between lg:w-full  mt-28">
          <div className="lg:w-[70%] max-sm:w-[60%]">
            <p className=" font-semibold text-[25px]">{lang === "ar" ? "عنوان" : "Address"}</p>
            <p className="lg:text-[18px] mt-5 max-sm:mt-3 text-[#454545]">
            {lang === "ar" ? poi.ar_address : poi.address}
            </p>
          </div>
          <div className="border-s-[3px] py-10 max-sm:hidden border-[#c0c0c0]"></div>
          <div className="lg:w-[50%] lg:ms-40 max-sm:mt-5">
            <p className=" font-semibold text-[25px]">{lang === "ar" ? "تواصل معنا مباشرة" : "Reach Out Directly"}</p>
            <div className="text-[#454545] text-[20px] mt-5 flex items-center">
              <LanguageIcon />
              <Link target="_blank" to={poi.contact?.website}>
                <p className="ms-5  lg:text-[18px]">Visit Website</p>
              </Link>
              <Link>
                {" "}
                <NorthEastIcon style={{ fontSize: "15px" }} />
              </Link>
            </div>
            <div className="text-[#454545] text-lg mt-2 flex items-center">
              <CallIcon />
              <Link>
                <p className="ms-5 lg:text-[18px]">{poi.contact?.phone}</p>
              </Link>
              {/* <Link>  <NorthEastIcon style={{fontSize:"15px"}}/></Link> */}
            </div>

            <div className="text-[#454545] text-lg mt-2 flex items-center">
              <EmailIcon />
              <a href={`mailto:${poi.contact?.email}`}>
                <p className="ms-5 lg:text-[18px]">
                  {poi.contact?.email}
                </p>
              </a>
              {/* <Link>  <NorthEastIcon style={{fontSize:"15px"}}/></Link> */}
            </div>
          </div>
        </div>

         {/* People also ask on Google  */}

         <div className="mt-28">
          <div className=" font-semibold text-[25px]">
          {lang === "ar" ? "يسأل الناس أيضًا على Google:" : "People also ask:"}
          </div>
          <div >
            <AccordionUsage qa={poi?.peopleSays} />
          </div>
        </div>

        {/* Important Tips */}
        <div className="mt-20   font-semibold text-[25px] ">Important Tips
        
        </div>
       
        <div className="flex overscroll-x-conatin overflow-x-scroll   py-5    gap-5">
          {/* <p className="">Important Tips</p> */}
          {poi.tips?.map((i,key) => (
            <ImportantTips tips={i} key={key}/>
          ))}
        </div>

        {/* Cities Nearby */}
        {Cookies.get("lang") === "ar" ? (
          <div className="flex justify-between flex-row-reverse lg:me-20 max-sm:ms-5 mt-20">
            <div>
              
              <p className="text-xl">جمال الهند</p>
              <p
                className="lg:text-4xl max-sm:text-2xl font-bold"
                style={{ color: `${primary}` }}
              >
                المدن القريبة
              </p>
            </div>

            <div
              className="lg:w-[65%] max-sm:w-[20%]"
              style={{ borderBottom: "1px solid black" }}
            ></div>

            <div className="max-sm:hidden ms-40 mt-4">
              <KeyboardArrowLeftIcon
                className="rounded-full cursor-pointer"
                style={{ border: "1px solid black", fontSize: "40px" }}
              />
              <KeyboardArrowRightIcon
                className="rounded-full ms-4 cursor-pointer"
                style={{ border: `1px solid ${primary}`, fontSize: "40px" }}
              />
            </div>
          </div>
        ) : (
          <div className="flex  max-sm:ms-5 mt-10">
            <div>
              <p className="text-[25px] font-light">Beauty Of India</p>
              <p
                className="lg:text-[40px] max-sm:text-2xl font-bold mt-2"
                style={{ color: `#5B27D5` }}
              >
                Nearby Cities
              </p>
            </div>
            <div
              className="lg:w-[70%] max-sm:w-[30%]"
              style={{ borderBottom: "1px solid black" }}
            ></div>
            {/* <div className="max-sm:hidden ms-14 mt-4">
              <KeyboardArrowLeftIcon
                className="rounded-full cursor-pointer"
                style={{ border: "1px solid black", fontSize: "40px" }}
              />
              <KeyboardArrowRightIcon
                className="rounded-full ms-4 cursor-pointer"
                style={{ border: `1px solid ${primary}`, fontSize: "40px" }}
              />
            </div> */}
          </div>
        )}
        {Cookies.get("lang") === "ar" ? (
          <div className="flex flex-row-reverse lg:ms-20 max-sm:ms-5 lg:gap-10 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">
            {category?.map((i,key) => (
              <PopularDestinationCard key={key} category={i} />
            ))}
          </div>
        ) : (
          <div className="flex  max-sm:ms-5 lg:gap-10 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">
            {category?.map((i,key) => (
              <PopularDestinationCard key={key} category={i} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Poi;
