import { primary } from "../../AppConfig";
import LatestBlogCard from "./LatestBlogCard";
import React from "react";
import Cookies from "js-cookie"
function LatestBlogs() {
    const cards=["1","2","3","4","5","6",]
    return ( 
        <>
        
        <div className="bg-gradient-to-b from-white via-[#f4f1cf] to-white pb-5">
        <div>
        {Cookies.get("lang")==="ar" ? 
         <div className="flex flex-row-reverse lg:me-20 max-sm:me-5 mt-10">
         <div>
         <p className="text-xl mt-5">Exploring</p>
         <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{color:`${primary}`}}>Latest Blogs</p>
         </div>
       <div className="lg:w-[60%] max-sm:w-[30%]" style={{borderBottom:"1px solid black"}}>
         
       </div>
      
     </div>
     :
     <div className="flex lg:ms-20 max-sm:ms-5 mt-10">
     <div>
     <p className="text-xl mt-5">Exploring</p>
     <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{color:`${primary}`}}>Latest Blogs</p>
     </div>
   <div className="lg:w-[72%] max-sm:w-[30%]" style={{borderBottom:"1px solid black"}}>
     
   </div>
  
 </div>
    }
           
          
        </div>

        {Cookies.get("lang")==="ar" ? 
          <div className="flex flex-row-reverse overscroll-x-conatin overflow-x-scroll mt-5 lg:ms-20 max-sm:ms-5 gap-5">
          {cards.map(i=>(
 <LatestBlogCard/>
          ))}
       
      </div>
      :
      <div className="flex overscroll-x-conatin overflow-x-scroll mt-5 lg:ms-20 max-sm:ms-5 gap-5">
      {cards.map(i=>(
<LatestBlogCard/>
      ))}
   
  </div>
        }
         {Cookies.get("lang")==="ar" ? 
        <div className=" flex flex-row-reverse lg:me-20 max-sm:me-5 ">
        <button className="border-2 border-black rounded-full mt-1 px-7 py-1 hover:bg-[#9747FF] hover:border-white hover:text-white ">Read All</button>
    </div>
    :
    <div className=" lg:ms-20 max-sm:ms-5 ">
            <button className="border-2 border-black rounded-full mt-1 px-7 py-1 hover:bg-[#9747FF] hover:border-white hover:text-white ">Read All</button>
        </div>
    }
      
        </div>
       
        
        </>
     );
}

export default LatestBlogs;