import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from "react";
import LatestBlogs from '../../Components/LatestBlogs';

function AllCategory() {
    return (
        <>
            <div>
                <div className='lg:ml-20 max-sm:ml-3'>
                    <div className="flex  mt-6">
                        <div style={{ color: "#6D6D6D" }}>Home<ChevronRightIcon className='mr-1 mb-0.5' /></div>
                        <div className='font-bold max-sm:hidden '>All Categories</div>
                    </div>
                    <div className=' lg:text-[55px] font-medium mt-8'>All Categories</div>
                    <div className='mt-5 flex overflow-x-scroll  space-x-2'>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Hill Stations
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Religious
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Best Hotels
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Global
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Europe
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Japanese
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Hill Stations
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Best Hotels
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Global
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Europe
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Hill Stations
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Best Hotels
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Europe
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Europe
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Hill Stations
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Europe
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Europe
                        </button>
                        <button className="text-[#6D6D6D] flex-none text-[13px] p-1 pl-3 pr-3 border border-[#cdd1d1] rounded-full hover:border-opacity-50">
                            Hill Stations
                        </button>
                    </div>
                </div>
                <div>

                    <div className="flex w-full px-20 max-sm:hidden mt-20 gap-10">
                        <div className="w-[950px] h-[524px]" id="trending-cat-card">
                            <img
                                className="w-[950px] h-[524px] rounded-lg object-cover cursor-pointer  "
                                id="trending-cat-card-img"
                                src="https://ihplb.b-cdn.net/wp-content/uploads/2016/09/Most-Famous-Historical-Forts-in-Rajasthan.jpg"
                                alt="Image 1"
                            />
                            <div
                                className="relative  bottom-12 z-10 px-5 "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[25px] ">
                                    Famous Historic Forts In India {" >"}
                                </h1>
                                <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                    Rising perpendicular and impregnable from a rocky hill that
                                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                    of the most magnificent forts in…
                                </p>
                                <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                    Read More
                                </button>
                            </div>
                        </div>
                        <div className="w-[550px] h-[524px]" id="trending-cat-card">
                            <img
                                className="w-[464px] h-[524px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-img"
                                src="https://img.veenaworld.com/wp-content/uploads/2023/01/Plan-a-%E2%80%98Monumental-Tour-to-Experience-the-Essence-of-India.jpg"
                                alt="Image 2"
                            />
                            <div
                                className="relative bottom-12 z-10 px-5 "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[25px]">
                                    Historical Monuments In India{" > "}
                                </h1>
                                <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                    Rising perpendicular and impregnable from a rocky hill that
                                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                    of the most magnificent forts in…
                                </p>
                                <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                    Read More
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full max-sm:hidden  px-20 mt-5 gap-10">
                        <div
                            className="w-[662px] h-[524px] max-sm:hidden rounded-lg object-cover cursor-pointer"
                            id="trending-cat-card"
                        >
                            <img
                                className="w-[662px] h-[524px]  rounded-lg object-cover"
                                id="trending-cat-card-img"
                                src="https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?cs=srgb&dl=pexels-fabianwiktor-994605.jpg&fm=jpg"
                                alt="Image 2"
                            />
                            <div
                                className="relative  bottom-12 z-10  px-5  "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[25px]">
                                    Famous Beaches In India{" >"}
                                </h1>
                                <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                    Rising perpendicular and impregnable from a rocky hill that
                                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                    of the most magnificent forts in…
                                </p>
                                <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                    Read More
                                </button>
                            </div>
                        </div>
                        <div className="space-y-5 ">
                            <div
                                className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-2"
                            >
                                <img
                                    className="w-[640px] h-[249px] rounded-lg object-cover"
                                    id="trending-cat-card-img-2"
                                    src="https://www.tourmyindia.com/blog//wp-content/uploads/2021/09/National-Parks-Reopening.jpg"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text-2"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        National Parks India{" >"}
                                    </h1>
                                    <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                        one of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div
                                className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-2"
                            >
                                <img
                                    className="w-[640px] h-[249px] rounded-lg object-cover"
                                    id="trending-cat-card-img-2"
                                    src="https://cityfurnish.com/blog/wp-content/uploads/2023/08/landscape-tea-plantations-india-kerala-munnar-min.jpg"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text-2"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        Beautiful Hill Staions In India{" >"}
                                    </h1>
                                    <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                        one of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" max-sm:hidden  ">
                        <div className="flex w-full px-20  mt-20 gap-10">
                            <div className="w-[950px] h-[524px]" id="trending-cat-card">
                                <img
                                    className="w-[950px] h-[524px] rounded-lg object-cover cursor-pointer  "
                                    id="trending-cat-card-img"
                                    src="https://ihplb.b-cdn.net/wp-content/uploads/2016/09/Most-Famous-Historical-Forts-in-Rajasthan.jpg"
                                    alt="Image 1"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text"
                                >
                                    <h1 className="text-white font-semibold text-[25px] ">
                                        Famous Historic Forts In India {" >"}
                                    </h1>
                                    <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                        of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div className="w-[550px] h-[524px]" id="trending-cat-card">
                                <img
                                    className="w-[464px] h-[524px] rounded-lg object-cover cursor-pointer"
                                    id="trending-cat-card-img"
                                    src="https://img.veenaworld.com/wp-content/uploads/2023/01/Plan-a-%E2%80%98Monumental-Tour-to-Experience-the-Essence-of-India.jpg"
                                    alt="Image 2"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        Historical Monuments In India{" "}
                                    </h1>
                                    <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                        of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full px-20 mt-5 gap-10">
                            <div
                                className="w-[662px] h-[524px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card"
                            >
                                <img
                                    className="w-[662px] h-[524px] rounded-lg object-cover"
                                    id="trending-cat-card-img"
                                    src="https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?cs=srgb&dl=pexels-fabianwiktor-994605.jpg&fm=jpg"
                                    alt="Image 2"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        Famous Beaches In India{" >"}
                                    </h1>
                                    <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                        of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div className="space-y-5">
                                <div
                                    className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                    id="trending-cat-card-2"
                                >
                                    <img
                                        className="w-[640px] h-[249px] rounded-lg object-cover"
                                        id="trending-cat-card-img-2"
                                        src="https://www.tourmyindia.com/blog//wp-content/uploads/2021/09/National-Parks-Reopening.jpg"
                                    />
                                    <div
                                        className="relative bottom-12 z-10 px-5 "
                                        id="trending-cat-text-2"
                                    >
                                        <h1 className="text-white font-semibold text-[25px]">
                                            National Parks India{" >"}
                                        </h1>
                                        <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                            Rising perpendicular and impregnable from a rocky hill that
                                            itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                            one of the most magnificent forts in…
                                        </p>
                                        <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                            Read More
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                    id="trending-cat-card-2"
                                >
                                    <img
                                        className="w-[640px] h-[249px] rounded-lg object-cover"
                                        id="trending-cat-card-img-2"
                                        src="https://cityfurnish.com/blog/wp-content/uploads/2023/08/landscape-tea-plantations-india-kerala-munnar-min.jpg"
                                    />
                                    <div
                                        className="relative bottom-12 z-10 px-5 "
                                        id="trending-cat-text-2"
                                    >
                                        <h1 className="text-white font-semibold text-[25px]">
                                            Beautiful Hill Staions In India{" >"}
                                        </h1>
                                        <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                            Rising perpendicular and impregnable from a rocky hill that
                                            itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                            one of the most magnificent forts in…
                                        </p>
                                        <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                            Read More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className=" max-sm:hidden  ">
                        <div className="flex w-full px-20  mt-20 gap-10">
                            <div className="w-[950px] h-[524px]" id="trending-cat-card">
                                <img
                                    className="w-[950px] h-[524px] rounded-lg object-cover cursor-pointer  "
                                    id="trending-cat-card-img"
                                    src="https://ihplb.b-cdn.net/wp-content/uploads/2016/09/Most-Famous-Historical-Forts-in-Rajasthan.jpg"
                                    alt="Image 1"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text"
                                >
                                    <h1 className="text-white font-semibold text-[25px] ">
                                        Famous Historic Forts In India {" >"}
                                    </h1>
                                    <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                        of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div className="w-[550px] h-[524px]" id="trending-cat-card">
                                <img
                                    className="w-[464px] h-[524px] rounded-lg object-cover cursor-pointer"
                                    id="trending-cat-card-img"
                                    src="https://img.veenaworld.com/wp-content/uploads/2023/01/Plan-a-%E2%80%98Monumental-Tour-to-Experience-the-Essence-of-India.jpg"
                                    alt="Image 2"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        Historical Monuments In India{" >"}
                                    </h1>
                                    <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                        of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full px-20 mt-5 gap-10">
                            <div
                                className="w-[662px] h-[524px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card"
                            >
                                <img
                                    className="w-[662px] h-[524px] rounded-lg object-cover"
                                    id="trending-cat-card-img"
                                    src="https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?cs=srgb&dl=pexels-fabianwiktor-994605.jpg&fm=jpg"
                                    alt="Image 2"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        Famous Beaches In India{" >"}
                                    </h1>
                                    <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                        of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div className="space-y-5">
                                <div
                                    className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                    id="trending-cat-card-2"
                                >
                                    <img
                                        className="w-[640px] h-[249px] rounded-lg object-cover"
                                        id="trending-cat-card-img-2"
                                        src="https://www.tourmyindia.com/blog//wp-content/uploads/2021/09/National-Parks-Reopening.jpg"
                                    />
                                    <div
                                        className="relative bottom-12 z-10 px-5 "
                                        id="trending-cat-text-2"
                                    >
                                        <h1 className="text-white font-semibold text-[25px]">
                                            National Parks India{" >"}
                                        </h1>
                                        <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                            Rising perpendicular and impregnable from a rocky hill that
                                            itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                            one of the most magnificent forts in…
                                        </p>
                                        <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                            Read More
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                    id="trending-cat-card-2"
                                >
                                    <img
                                        className="w-[640px] h-[249px] rounded-lg object-cover"
                                        id="trending-cat-card-img-2"
                                        src="https://cityfurnish.com/blog/wp-content/uploads/2023/08/landscape-tea-plantations-india-kerala-munnar-min.jpg"
                                    />
                                    <div
                                        className="relative bottom-12 z-10 px-5 "
                                        id="trending-cat-text-2"
                                    >
                                        <h1 className="text-white font-semibold text-[25px]">
                                            Beautiful Hill Staions In India{" >"}
                                        </h1>
                                        <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                            Rising perpendicular and impregnable from a rocky hill that
                                            itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                            one of the most magnificent forts in…
                                        </p>
                                        <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                            Read More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="lg:hidden flex px-5 mt-5 overscroll-x-conatin  gap-5">
                        <div className="w-[277px] h-[112.44px] flex-none">
                            <img
                                className="w-[277px] h-[112.44px] rounded-md"
                                src="https://www.nationaloutdoorfurniture.com/files/Blog/the-importance-of-public-parks3.jpg"
                            />
                            <div
                                className="relative bottom-5 z-10 px-3 "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[12px]">
                                    National Parks In India {" >"}
                                </h1>
                                
                            </div>
                        </div>
                    </div>
                    <div className="lg:hidden flex px-5 mt-5 overscroll-x-conatin  gap-5">
                        <div className="w-[277px] h-[112.44px] flex-none">
                            <img
                                className="w-[277px] h-[112.44px] rounded-md"
                                src="https://media.cntraveler.com/photos/61eae2a9fe18edcbd885cb01/16:9/w_2580,c_limit/Seychelles_GettyImages-1169388113.jpg"
                            />
                            <div
                                className="relative bottom-5 z-10 px-3 "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[12px]">
                                    Famous Beaches In India {" >"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="lg:hidden flex px-5 mt-5 overscroll-x-conatin  gap-5">
                        <div className="w-[277px] h-[112.44px] flex-none">
                            <img
                                className="w-[277px] h-[112.44px] rounded-md"
                                src="https://www.thoughtco.com/thmb/l0Ei2qSYEp6vtU6a1o0FtphhV4s=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/SaharaDesert-58c1a5603df78c353c3d525d.jpg"
                            />
                            <div
                                className="relative bottom-5 z-10 px-3 "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[12px]">
                                    Famous desert in Rajasthan{" >"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className=' mt-40 mb-40 max-sm:mt-0'>
                        <LatestBlogs />
                    </div>
                </div>

            </div>
        </>
    );
}

export default AllCategory;