import React from "react";
import banner from "../../public/images/banner-hop-days.png"
function Banner() {
    return ( 
        <>
     <div className="lg:w-full">
      <div>
        <img src={banner} className="lg:h-[70%] max-sm:h-[200px]"/>
      </div>
      <div className="absolute lg:bottom-[200px] lg:p-10 max-sm:bottom-[460px] lg:ms-20 max-sm:ms-5 text-white ">
        <h1 className="lg:text-[65px] font-md">South Africa</h1>
        <p className="mt-2 text-[20px] font-thin">A Journey of a Lifetime</p>
        <button className="border-2 border-white rounded-full lg:px-6 max-sm:px-4 py-1 mt-3">Explore</button>
      </div>
     </div>
        </>
     );
}

export default Banner;