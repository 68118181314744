import React from "react";
import { useEffect, useState } from "react";
import { primary, url } from "../../AppConfig";
import AttractionCard from "./AttractionCard";
import CardList from "../Shimmer/Cards";
import Cookies from "js-cookie"
import axios from "axios";
function Attractions({poi,load}) {
    // const cards=["1","2","3","4","5","6",]
    // const [poi,setPoi]=useState(poi)
    
    // useEffect(() => {
    //     // console.log("Params:", poiId); // Log params
    //     // console.log("Initial Data:", initialData); // Log initial data
    // // console.log(poiData)
    //     if (poiData===undefined ) {
    //       const fetchPoiData = async () => {
    //         try {
    //           const poiResponse = await axios.get(`http://45.90.220.36:8080/api/get-all-pois`);
    //           setPoi(poiResponse.data.data);
    //           // console.log(poiResponse.data)
              
             
    //           setLoad(true);
    //         } catch (error) {
    //           console.error("Error fetching data on client-side navigation:", error);
    //           setLoad(false);
    //         }
    //       };
    
    //       fetchPoiData();
    //     } 
    //   }, [ poi]);
    return ( 
        <>
        
        {load===true? 
   
   <div className="bg-gradient-to-b from-white via-[#f4f1cf] to-white py-2">


    <div>
     {Cookies.get("lang")==="ar" ? 
     <div className="flex lg:ms-20 max-sm:ms-5 mt-10">
    
   <div className="lg:w-[60%] max-sm:w-[30%]" style={{borderBottom:"1px solid black"}}>
     
   </div>
   <div>
     <p className="text-xl">Exploring</p>
     <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{color:`${primary}`}}>Must-see Attractions</p>
     </div>
 </div>
 :
 <div className="flex lg:ms-20 max-sm:ms-5 mt-10">
            <div>
            <p className="text-xl">Exploring</p>
            <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{color:`${primary}`}}>Must-see Attractions</p>
            </div>
          <div className="lg:w-[60%] max-sm:w-[30%]" style={{borderBottom:"1px solid black"}}>
            
          </div>
         
        </div>
}
        
      
    </div>
    {Cookies.get("lang")==="ar" ? 
     <div className="flex  flex-row-reverse lg:ms-20 max-sm:ms-5 lg:gap-10 max-sm:gap-5 overflow-y-hidden  overscroll-x-conatin overflow-x-scroll mt-5">
     {poi.map((i,key)=>(
<AttractionCard key={key}  poi={i}/>
     ))}
 
 </div>
 :
 <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-10 max-sm:gap-5 overflow-y-hidden overscroll-x-conatin overflow-x-scroll mt-5">
 {poi.map((i,key)=>(
<AttractionCard key={key} poi={i}/>
 ))}

</div>
}
          {Cookies.get("lang")==="ar" ? 
            <div className=" flex flex-row-reverse lg:me-20 max-sm:me-5 ">
            <button className="border-2 border-black rounded-full  -mt-8 px-7 py-1 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
        </div>
        :
        <div className=" lg:ms-20 max-sm:ms-5 ">
        <button className="border-2 border-black rounded-full  -mt-8 px-7 py-1 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
    </div>
        }
    </div>
    :  
    <div className="flex px-20">
    <CardList/>
</div>  
    }
      
        
      
        </>
     );
}

export default Attractions;