import React from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { primary, url } from "../../AppConfig";
import PopularDestinationCard from "../../Components/PopularDestination/PopularDestinationCard";
import { useEffect, useState } from "react";
import CardList2 from "../../Components/Shimmer/card2";
import Cookies from "js-cookie";
import AttractionCard from "../../Components/Attractions/AttractionCard";
import CardList from "../../Components/Shimmer/Cards";
import AllCuisines from "../../Components/cuisines";
import LatestBlogs from "../../Components/LatestBlogs";



function AllCity() {
    const [category, setCategory] = useState([]);
    const [load, setLoad] = useState(false);
    const [poi, setPoi] = useState([])
    

    useEffect(() => {
        fetch(`${url}/all-category`, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then((response) => {
                if (response.status === true) {
                    setCategory(response.data)
                    console.log(response.data)
                    setLoad(true)
                }

            })
    }, [])

    fetch(`${url}/get-all-pois`, {
        headers: { "Content-Type": "application/json" }
    }).then((res) => { return res.json() })
        .then(response => {

            if (response.status == true) {
                setPoi(prevPoi => [...prevPoi, ...response.data]);
                setLoad(true)
                console.log(response)
            }
        })
    return (
        <>
            <div>
                <div className="w-full ">
                    <div>
                        <img className="h-[480px] max-sm:h-[200px] w-full" src="https://www.tourism.rajasthan.gov.in/content/dam/rajasthan-tourism/english/city/menu/8.jpg" />
                    </div>

                    <div className="absolute  lg:bottom-[340px] max-sm:bottom-[500px]  lg:ms-20 max-sm:ms-4 text-white ">
                        <p className="mb-64">Asia{" > "}India{" > "}Rajasthan</p>
                        <h1 className="  lg:text-[50px] max-sm:text-[23px]  font-medium">Rajasthan</h1>
                        <p style={{ marginBottom: "-90px" }} className="mt-2 text-[20px]">India,Asia</p>
                    </div>
                </div>
                <div className="flex  max-sm:flex-col ">
                    <div className="text-center flex justify-center max-sm:w-full ml-20 max-sm:ms-0   items-center gap-28  mt-20 max-sm:mt-8 w-[50%]">
                        <div>
                            <h1 className="italic max-sm:text-[23px] font-bold text-[30px] ">"Padharo Mhare Desh"</h1>
                            

                            <p className=" text-[20px] max-sm:text-[16px] mt-5 max-sm:mt-1 text-center leading-6 max-sm:leading-5 italic max-sm:ms-1  ">Here is India's archstpal land of maharaja's and
                                medievals forts,places and tigers,and kaleidoscopic
                                festivals.Rajasthan really is the jewel in India's crown.</p>
                        </div>
                        <div className=" border-r-2 max-sm:hidden border-[#cacbcc] h-40"></div>
                    </div>
                    <div className=" flex overflow-x-scroll items-center ml-5 mt-20 max-sm:mt-10 space-x-5 w-[50%] max-sm:w-full max-sm:ms-4">
                        <div className="lg:w-[60%] max-sm:w-[280px]  flex-none   bg-[#FFFEEF] h-[150px] rounded-xl shadow-lg">
                            <div className="ms-2  mt-1">
                                <p className=" mt-5 text-[18px] max-sm:text-[15px] text-center font-bold ">Best Time to visit(Oct.-Mar.)</p>
                                <p className="mt-8 max-sm:mt-7 text-center items-center text-[15px]">The temperature range from 10&deg;c to 27&deg;c, making it ideal for outdoor  activities and for fun.</p>
                            </div>
                        </div>
                        <div className="lg:w-[60%] max-sm:w-[280px] flex-none  bg-[#FFFEEF] h-[150px] rounded-xl shadow-lg">
                            <div className="ms-2  mt-1">
                                <p className=" mt-5 text-[18px] max-sm:text-[15px] text-center font-bold ">Best Time to visit(Oct.-Mar.)</p>
                                <p className="mt-8 max-sm:mt-7 text-center text-[15px]">The temperature range from 10&deg;c to 27&deg;c, making it ideal for outdoor  activities and for fun.</p>
                            </div>
                        </div>
                        <div className="lg:w-[60%] max-sm:w-[280px]   flex-none  bg-[#FFFEEF] h-[150px] rounded-xl shadow-lg">
                            <div className="ms-2  mt-1">
                                <p className=" mt-5 text-[18px] max-sm:text-[15px]  text-center font-bold ">Best Time to visit(Oct.-Mar.)</p>
                                <p className="mt-8 max-sm:mt-7 text-center text-[15px]">The temperature range from 10&deg;c to 27&deg;c, making it ideal for outdoor  activities and for fun.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex lg:ms-20 max-sm:ms-4 mt-32 max-sm:mt-10">
                    <div>
                        <p className="text-xl">Where To Go</p>
                        <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{ color: `${primary}` }}>Popular Destinations</p>
                    </div>
                    <div className="lg:w-[50%]" style={{borderBottom: "1px solid black"}}>

                    </div>
                    <div className="max-sm:hidden ms-10 mt-4">
                        <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{ border: "1px solid black", fontSize: "40px" }} />
                        <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer" style={{ border: `1px solid ${primary}`, fontSize: "40px" }} />
                    </div>
                </div>
                {load === false ?
                    <div>
                        <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">

                            <CardList2 />
                            <CardList2 />
                            <CardList2 />
                            <CardList2 />
                            <CardList2 />

                        </div>
                    </div>
                    :

                    <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">
                        {category.map((i) => (
                            <PopularDestinationCard key={i._id} category={i} />
                        ))}
                    </div>
                }
                <div className="lg:mt-0 mb-5 lg:ms-20 max-sm:ms-5 ">
                    <button className="border-2 border-black rounded-full mt-3 px-5 py-2 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
                </div>
                <div>
                    {load === true ?

                        <div className="bg-gradient-to-r from-yellow-50  to-yellow-50 py-2">
                            <div>
                                {Cookies.get("lang") === "ar" ?
                                    <div className="flex lg:ms-20 max-sm:ms-5 mt-10">

                                        <div className="lg:w-[50%] max-sm:w-[30%]" style={{ borderBottom: "1px solid black" }}>

                                        </div>
                                        <div>
                                            <p className="text-xl">Exploring</p>
                                            <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{ color: `${primary}` }}>Rajasthan's Beauty</p>
                                        </div>
                                    </div>
                                    :
                                    <div className="flex lg:ms-20 max-sm:ms-5 mt-10">
                                        <div>
                                            <p className="text-xl">Exploring</p>
                                            <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{ color: `${primary}` }}>Rajasthan's Beauty</p>
                                        </div>
                                        <div className="lg:w-[50%] " style={{ borderBottom: "1px solid black" }}>

                                        </div>

                                    </div>
                                }


                            </div>
                            {Cookies.get("lang") === "ar" ?
                                <div className="flex  flex-row-reverse lg:ms-20 max-sm:ms-5 lg:gap-10 max-sm:gap-5 overflow-y-hidden  overscroll-x-conatin overflow-x-scroll mt-5">
                                    {poi.map((i) => (
                                        <AttractionCard key={i._id} poi={i} />
                                    ))}

                                </div>
                                :
                                <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-10 max-sm:gap-5 overflow-y-hidden overscroll-x-conatin overflow-x-scroll mt-5">
                                    {poi.map((i) => (
                                        <AttractionCard key={i._id} poi={i} />
                                    ))}

                                </div>
                            }
                            {Cookies.get("lang") === "ar" ?
                                <div className="lg:mt-10 flex flex-row-reverse lg:me-20 max-sm:me-5 ">
                                    <button className="border-2 border-black rounded-full mt-5 max-sm:mt-1 px-5 py-2 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
                                </div>
                                :
                                <div className="lg:mt-10 lg:ms-20 max-sm:ms-5 ">
                                    <button className="border-2 border-black rounded-full mt-5 max-sm:mt-1 px-5 py-2 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
                                </div>
                            }
                        </div>
                        :
                        <div className="flex px-20">
                            <CardList />
                        </div>
                    }



                </div>

                <div>
                    <div className="flex flex-row-start ml-20 max-sm:ms-4 mt-20">
                        <div>
                            <p className="text-xl">Immerse Yourself</p>
                            <p
                                className="lg:text-4xl max-sm:text-2xl font-bold"
                                style={{ color: `${primary}` }}
                            >
                                Trending Categories
                            </p>
                        </div>
                        <div
                            className="lg:w-[50%] "
                            style={{ borderBottom: "1px solid black" }}
                        ></div>
                    </div>
                    <div className="flex w-full px-20 max-sm:hidden mt-20 gap-10">
                        <div className="w-[950px] h-[524px]" id="trending-cat-card">
                            <img
                                className="w-[950px] h-[524px] rounded-lg object-cover cursor-pointer  "
                                id="trending-cat-card-img"
                                src="https://www.tourism.rajasthan.gov.in/content/dam/rajasthan-tourism/english/city/explore/12.jpg"
                                alt="Image 1"
                            />
                            <div
                                className="relative  bottom-12 z-10 px-5 "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[25px] ">
                                    Famous Historic Forts In Rajasthan {" >"}
                                </h1>
                                <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                    Rising perpendicular and impregnable from a rocky hill that
                                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                    of the most magnificent forts in…
                                </p>
                                <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                    Read More
                                </button>
                            </div>
                        </div>
                        <div className="w-[550px] h-[524px]" id="trending-cat-card">
                            <img
                                className="w-[464px] h-[524px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-img"
                                src="https://im.hunt.in/cg/raj/About/Tourism/Vijay-stambh.jpg"
                                alt="Image 2"
                            />
                            <div
                                className="relative bottom-12 z-10 px-5 "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[20px]">
                                    Historical Monuments In Rajasthan{" > "}
                                </h1>
                                <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                    Rising perpendicular and impregnable from a rocky hill that
                                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                    of the most magnificent forts in…
                                </p>
                                <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                    Read More
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full max-sm:hidden  px-20 mt-5 gap-10">
                        <div
                            className="w-[662px] h-[524px] max-sm:hidden rounded-lg object-cover cursor-pointer"
                            id="trending-cat-card"
                        >
                            <img
                                className="w-[662px] h-[524px]  rounded-lg object-cover"
                                id="trending-cat-card-img"
                                src="https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?cs=srgb&dl=pexels-fabianwiktor-994605.jpg&fm=jpg"
                                alt="Image 2"
                            />
                            <div
                                className="relative  bottom-12 z-10  px-5  "
                                id="trending-cat-text"
                            >
                                <h1 className="text-white font-semibold text-[25px]">
                                    Famous Beaches In Rajasthan{" >"}
                                </h1>
                                <p className="hidden paragraph text-white font-medium text-[25px] mt-2">
                                    Rising perpendicular and impregnable from a rocky hill that
                                    itself stands 120m above Jodhpur’s skyline, Mehrangarh is one
                                    of the most magnificent forts in…
                                </p>
                                <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button py-1">
                                    Read More
                                </button>
                            </div>
                        </div>
                        <div className="space-y-5 ">
                            <div
                                className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-2"
                            >
                                <img
                                    className="w-[640px] h-[249px] rounded-lg object-cover"
                                    id="trending-cat-card-img-2"
                                    src="https://www.tourmyindia.com/blog//wp-content/uploads/2021/09/National-Parks-Reopening.jpg"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text-2"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        National Parks Rajasthan{" >"}
                                    </h1>
                                    <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                        one of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div
                                className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-2"
                            >
                                <img
                                    className="w-[640px] h-[249px] rounded-lg object-cover"
                                    id="trending-cat-card-img-2"
                                    src="https://cityfurnish.com/blog/wp-content/uploads/2023/08/landscape-tea-plantations-india-kerala-munnar-min.jpg"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text-2"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        Beautiful Hill Staions In Rajasthan{" >"}
                                    </h1>
                                    <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                        one of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full max-sm:hidden lg:hidden px-20 mt-5 gap-10">
                        
                        <div className="space-y-5  ">
                            <div
                                className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-2"
                            >
                                <img
                                    className="w-[640px] h-[249px] rounded-lg object-cover"
                                    id="trending-cat-card-img-2"
                                    src="https://www.tourmyindia.com/blog//wp-content/uploads/2021/09/National-Parks-Reopening.jpg"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text-2"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        National Parks Rajasthan{" >"}
                                    </h1>
                                    <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                        one of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                            <div
                                className="w-[640px] h-[249px] rounded-lg object-cover cursor-pointer"
                                id="trending-cat-card-2"
                            >
                                <img
                                    className="w-[640px] h-[249px] rounded-lg object-cover"
                                    id="trending-cat-card-img-2"
                                    src="https://cityfurnish.com/blog/wp-content/uploads/2023/08/landscape-tea-plantations-india-kerala-munnar-min.jpg"
                                />
                                <div
                                    className="relative bottom-12 z-10 px-5 "
                                    id="trending-cat-text-2"
                                >
                                    <h1 className="text-white font-semibold text-[25px]">
                                        Beautiful Hill Staions In Rajasthan{" >"}
                                    </h1>
                                    <p className="hidden paragraph-2 text-white font-medium text-[25px] mt-2">
                                        Rising perpendicular and impregnable from a rocky hill that
                                        itself stands 120m above Jodhpur’s skyline, Mehrangarh is
                                        one of the most magnificent forts in…
                                    </p>
                                    <button className="border-2 border-white px-3 rounded-full text-white hidden mt-5 button-2 py-1">
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="lg:mt-0 mb-5 lg:ms-20 max-sm:ms-5 ">
                        <button className="border-2 border-black rounded-full mt-8 px-5 py-2 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
                    </div>

                </div>

                <div>
                    <div className="flex flex-row-start ml-20 max-sm:ms-4  mt-20 max-sm:mt-10">
                        <div>
                            <p className="text-xl">Explore Best</p>
                            <p
                                className="lg:text-4xl max-sm:text-2xl font-bold"
                                style={{ color: `${primary}` }}
                            >
                                Hotels in Rajasthan
                            </p>
                        </div>
                        <div
                            className="lg:w-[50%] "
                            style={{ borderBottom: "1px solid black" }}
                        ></div>
                    </div>
                    <div className="flex space-x-6 max-sm:space-x-2 overflow-x-scroll max-sm:ms-4   mt-10 max-sm:mt-2 scrollbar-hidden ml-20">
                        <div className="lg:w-[20%] max-sm:w-[60%] flex-none   bg-white pb-5  ">
                            <div >
                                <img className="rounded-2xl" src="https://assets.architecturaldigest.in/photos/623070b7f97ac141cf68d7ae/master/w_1600%2Cc_limit/RAAS%2520Chhatrasagar_photo%2520Avesh%2520Gaur.jpeg" />
                            </div>
                            <div className=" test-start mt-2">
                                <p className="font-bold text-[20px] max-sm:text-[18px]">The Taj Hotel</p>
                                <p className="mt-2 text-[15px] max-sm:text-[13px]">from  ₹ 15,000/night</p>
                            </div>
                        </div>
                        <div className="lg:w-[20%] max-sm:w-[60%] flex-none   ml-20 bg-white pb-5  ">
                            <div >
                                <img className="rounded-2xl" src="https://assets.architecturaldigest.in/photos/623070b7f97ac141cf68d7ae/master/w_1600%2Cc_limit/RAAS%2520Chhatrasagar_photo%2520Avesh%2520Gaur.jpeg" />
                            </div>
                            <div className=" test-start mt-2">
                                <p className="font-bold text-[20px] max-sm:text-[18px]">The Taj Hotel</p>
                                <p className="mt-2 text-[15px]  max-sm:text-[13px]">from  ₹ 15,000/night</p>
                            </div>
                        </div>
                        <div className="lg:w-[20%] max-sm:w-[60%] flex-none   ml-20 bg-white pb-5  ">
                            <div >
                                <img className="rounded-2xl" src="https://assets.architecturaldigest.in/photos/623070b7f97ac141cf68d7ae/master/w_1600%2Cc_limit/RAAS%2520Chhatrasagar_photo%2520Avesh%2520Gaur.jpeg" />
                            </div>
                            <div className=" test-start mt-2">
                                <p className="font-bold text-[20px] max-sm:text-[18px]">The Taj Hotel</p>
                                <p className="mt-2 text-[15px]  max-sm:text-[13px]">from  ₹ 15,000/night</p>
                            </div>
                        </div>
                        <div className="lg:w-[20%] max-sm:w-[60%] flex-none   ml-20 bg-white pb-5  ">
                            <div >
                                <img className="rounded-2xl" src="https://assets.architecturaldigest.in/photos/623070b7f97ac141cf68d7ae/master/w_1600%2Cc_limit/RAAS%2520Chhatrasagar_photo%2520Avesh%2520Gaur.jpeg" />
                            </div>
                            <div className=" test-start mt-2">
                                <p className="font-bold text-[20px] max-sm:text-[18px]">The Taj Hotel</p>
                                <p className="mt-2 text-[15px]  max-sm:text-[13px]">from  ₹ 15,000/night</p>
                            </div>
                        </div>
                        <div className="lg:w-[20%] max-sm:w-[60%] flex-none   ml-20 bg-white pb-5  ">
                            <div >
                                <img className="rounded-2xl" src="https://assets.architecturaldigest.in/photos/623070b7f97ac141cf68d7ae/master/w_1600%2Cc_limit/RAAS%2520Chhatrasagar_photo%2520Avesh%2520Gaur.jpeg" />
                            </div>
                            <div className=" test-start mt-2">
                                <p className="font-bold text-[20px] max-sm:text-[18px]">The Taj Hotel</p>
                                <p className="mt-2 text-[15px]  max-sm:text-[13px]">from  ₹ 15,000/night</p>
                            </div>
                        </div>
                        <div className="lg:w-[20%] max-sm:w-[60%] flex-none   ml-20 bg-white pb-5  ">
                            <div >
                                <img className="rounded-2xl" src="https://assets.architecturaldigest.in/photos/623070b7f97ac141cf68d7ae/master/w_1600%2Cc_limit/RAAS%2520Chhatrasagar_photo%2520Avesh%2520Gaur.jpeg" />
                            </div>
                            <div className=" test-start mt-2">
                                <p className="font-bold text-[20px] max-sm:text-[18px]">The Taj Hotel</p>
                                <p className="mt-2 text-[15px]  max-sm:text-[13px]">from  ₹ 15,000/night</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <AllCuisines />
                </div>
                <div className="">
                    <LatestBlogs />
                </div>




            </div>
        </>
    );
}

export default AllCity;