import React from "react";
import { Divider } from "@mui/material";
import PopularDestinationCard from "./PopularDestinationCard";
import { primary, url } from "../../AppConfig";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from "react";
import CardList2 from "../Shimmer/card2";
import Cookies from "js-cookie"
import axios from "axios";
import PlaceIcon from '@mui/icons-material/Place';

function PopularDestination({popular,load}) {
    // let load =true
    console.log(popular)
    // const cards=["1","2","3","4","5","6",]
    // const [category,setCategory]=useState(categoryData)
    // const [load,setLoad]=useState(!!category)
    // useEffect(() => {
    //     // console.log("Params:", poiId); // Log params
    //     // console.log("Initial Data:", initialData); // Log initial data
    
    //   //   if (categoryData===undefined ) {
    //   //       console.log("hello")
    //   //     const fetchPoiData = async () => {
    //   //       try {
             
    //   //         const categoryResponse = await axios.get(`http://45.90.220.36:8080/api/all-category`);
    //   //         setCategory(categoryResponse.data.data);
    //   //         console.log(categoryResponse.data)
    //   //         setLoad(true);
    //   //       } catch (error) {
    //   //         console.error("Error fetching data on client-side navigation:", error);
    //   //         setLoad(false);
    //   //       }
    //   //     };
    
    //   //     fetchPoiData();
    //   //   }
    //   // }, [ categoryData]);
    return ( 
        <>
        <div>
            {Cookies.get("lang")==="ar" ?   <div className="flex lg:ms-20 max-sm:ms-5 mt-10">
               
              
              {/* <div className="max-sm:hidden ms-10 mt-4">
              <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{border:"1px solid black",fontSize:"40px"}}/>
              <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer" style={{border:`1px solid ${primary}`,fontSize:"40px"}}/>
              </div> */}
              <div className="lg:w-[60%] max-sm:w-[30%]" style={{borderBottom:"1px solid black"}}>
                
              </div>
              <div>
                <p className="text-xl">الى اين اذهب</p>
                <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{color:`${primary}`}}>الوجهات الشعبية</p>
                </div>
            </div>
            :
            <div className="flex lg:ms-20 max-sm:ms-5 mt-10">
                <div>
                <p className="text-xl">Where To Go</p>
                <p className="lg:text-4xl max-sm:text-2xl font-bold" style={{color:`${primary}`}}>Popular Destinations</p>
                </div>
              <div className="lg:w-[60%] max-sm:w-[30%]" style={{borderBottom:"1px solid black"}}>
                
              </div>
              {/* <div className="max-sm:hidden ms-10 mt-4">
              <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{border:"1px solid black",fontSize:"40px"}}/>
              <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer" style={{border:`1px solid ${primary}`,fontSize:"40px"}}/>
              </div> */}
            </div>
}
        </div>
        {/* {Cookies.get("lang")==="ar" ?  */}
        {/* <div className="flex flex-row-reverse lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">
        {category.map(i=>(
<PopularDestinationCard category={i}/>
        ))}
    </div> */}
   
    {load===false ?
    <div>
        <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">
            
   <CardList2 />
   <CardList2 />
   <CardList2 />
   <CardList2 />
   <CardList2 />
           
        </div>
    </div>
    :

    <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-10 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">
            {popular?.map((i,key)=>(
//    <PopularDestinationCard key={key} category={i}/>
<div className="max-sm:w-[40%] lg:w-[20%] rounded-md text-center cursor-pointer flex-none" id="popular-destination-card" key={key}>
<div id="popular-destination-card-image">
  <img
    src={i.image}
    alt={i.categoryName || "Category Image"}
    className="rounded-xl lg:w-[276px] lg:h-[350px] max-sm:w-[120px] max-sm:h-[158px]"
  />
</div>
<div className="relative text-center bottom-[60px]" id="popular-destination-card-text">
  <p className="text-white font-bold max-sm:text-xl lg:text-2xl">
    {i.categoryName}
  </p>
  <p className="text-white">
    <PlaceIcon /> {i.slug}
  </p>
</div>
</div>
            ))}
        </div>
}
    {/* } */}
        {/* {Cookies.get("lang")==="ar" ? 
         <div className="lg:mt-10 flex flex-row-reverse lg:me-20 max-sm:me-5 ">
         <button className="border-2 border-black rounded-full mt-5 px-5 py-2 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
     </div>
     : */}
     <div className=" mb-5 lg:ms-20 max-sm:ms-5 ">
     <button className="border-2 border-black rounded-full -mt-10 px-7 py-1 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More</button>
 </div>
    {/* } */}
       
        </>
     );
}

export default PopularDestination;