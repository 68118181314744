import React from "react";
import { primary, url } from "../../AppConfig";
function AllCuisines() {
    return (
        <>
            <div>
                <div className="flex flex-row-start ml-20 max-sm:ms-4 mt-20 max-sm:mt-2">
                    <div>
                        <p className="text-xl">Explore Best</p>
                        <p
                            className="lg:text-4xl max-sm:text-[23px] font-bold"
                            style={{ color: `${primary}` }}
                        >
                            Cuisines of Rajasthan
                        </p>
                    </div>
                    <div
                        className="lg:w-[50%] "
                        style={{ borderBottom: "1px solid black" }}
                    ></div>
                </div>
                <div className="flex space-x-6 max-sm:space-x-2 overflow-x-scroll max-sm:ms-4 mt-10 max-sm:mt-2  ml-20">
                    <div className="lg:w-[20%] max-sm:w-[60%]  flex-none    bg-white pb-5  ">
                        <div >
                            <img className="rounded-2xl" src="https://static.toiimg.com/photo/84442976.cms" />
                        </div>
                        <div className="">
                            <p className="font-bold mt-3 ml-1 text-[20px] max-sm:text-[16px]">Ghewar</p>

                        </div>
                    </div>
                    <div className="lg:w-[20%] max-sm:w-[60%]  flex-none    bg-white pb-5  ">
                        <div >
                            <img className="rounded-2xl" src="https://static.toiimg.com/photo/84442976.cms" />
                        </div>
                        <div className="">
                            <p className="font-bold mt-3 ml-1 text-[20px] max-sm:text-[16px]">Ghewar</p>

                        </div>
                    </div>
                    <div className="lg:w-[20%] max-sm:w-[60%]  flex-none    bg-white pb-5  ">
                        <div >
                            <img className="rounded-2xl" src="https://static.toiimg.com/photo/84442976.cms" />
                        </div>
                        <div className="">
                            <p className="font-bold mt-3 ml-1 text-[20px] max-sm:text-[16px]">Ghewar</p>

                        </div>
                    </div>
                    <div className="lg:w-[20%] max-sm:w-[60%]  flex-none    bg-white pb-5  ">
                        <div >
                            <img className="rounded-2xl" src="https://static.toiimg.com/photo/84442976.cms" />
                        </div>
                        <div className="">
                            <p className="font-bold mt-3 ml-1 text-[20px] max-sm:text-[16px]">Ghewar</p>

                        </div>
                    </div>
                    <div className="lg:w-[20%] max-sm:w-[60%]  flex-none    bg-white pb-5  ">
                        <div >
                            <img className="rounded-2xl" src="https://static.toiimg.com/photo/84442976.cms" />
                        </div>
                        <div className="">
                            <p className="font-bold mt-3 ml-1 text-[20px] max-sm:text-[16px]">Ghewar</p>

                        </div>
                    </div>
                    
                    
                    
                   
                   

                </div>
            </div>
        </>

    );
}

export default AllCuisines;