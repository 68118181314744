import React from 'react';
import { useState } from 'react';
import { Box, MenuItem, FormControl, Select, InputLabel, Typography, Button } from '@mui/material';

function BlogDropdown() {
  const [sortBy, setSortBy] = useState('Popular');
  const [country, setCountry] = useState('All');
  const [language, setLanguage] = useState('English');
  const [category, setCategory] = useState('Historic Places');

  const handleClearAll = () => {
    setCountry('All');
    setLanguage('English');
    setCategory('Historic Places');
    
  };

  return (
    <Box className="max-sm:hidden" sx={{ width: 280, border: '1px solid #ccc', borderRadius: '8px', padding: '12px',marginTop:"33px" }}>
      <FormControl fullWidth sx={{ marginBottom: '16px' }}>
        <InputLabel>Sort by</InputLabel>
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          label="Sort by"
          className='bg-gray-100'
        >
          <MenuItem value="Popular">Popular</MenuItem>
          <MenuItem value="Newest">Newest</MenuItem>
          <MenuItem value="Rating">Rating</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Filter</Typography>
        <Button color="error" onClick={handleClearAll}>
          clear
        </Button>
      </Box>

      <FormControl fullWidth sx={{ marginTop: '16px' }}>
        <InputLabel>Country</InputLabel>
        <Select
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          label="Country"
           className='bg-gray-100'
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="All">India</MenuItem>
          <MenuItem value="USA">USA</MenuItem>
          <MenuItem value="Canada">Canada</MenuItem>
          <MenuItem value="UK">UK</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: '16px' }}>
        <InputLabel>Language</InputLabel>
        <Select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          label="Language"
           className='bg-gray-100'
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="French">Arabic</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth  sx={{ marginTop: '16px' }}>
        <InputLabel>Category</InputLabel>
        <Select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          label="Category"
           className='bg-gray-100'
        >
          <MenuItem value="Historic Places">Historic Places</MenuItem>
          <MenuItem value="Museums">Museums</MenuItem>
          <MenuItem value="Parks">Parks</MenuItem>
          <MenuItem value="Hills">Hills</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default BlogDropdown;
