import React from "react";
import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie"
function AttractionCard({poi,key}) {
    // const [poi,setPois]=useState({})
    const [image,setImage]=useState("")
    const [shortDescription,setShortDescription]=useState("")
 const lang=   Cookies.get("lang") || "en"
 

 
    useEffect(()=>{
    //  setPois(props.poi)
     setImage(poi?.images[0])
     setShortDescription(poi?.shortDescription)
    //  console.log(props)
    },[poi])
    let url =`${poi.slug}-${poi.city}-${poi.country}-poi-${poi._id}`
  let  url2 = url.toLowerCase().replace(/\s+/g, '-');
let city = `${poi.city}`
let city2 =city.toLocaleLowerCase().replace(/\+/g, '-')
    let navigate=useNavigate()
    function truncateText(text, maxLength) {
        if (text?.length <= maxLength) {
          return text;
        }
        return text.substring(0, maxLength) + '...';
      }
    // const truncatedDescription = truncateText(poi?.shortDescription, 100);
    return ( 
        <>
        {/* <NavLink to={`/${lang}/${poi.city}/${poi.slug}/${poi.city}/${poi.country}/poi/${poi._id}`}> */}
        {lang==="ar" ?
        <div key={key} className="lg:w-[404px]  max-sm:w-[268px] flex-none mb-5 cursor-pointer" onClick={()=>{navigate(`/ar/place/${city2}/${url2}`); window.scrollTo({
          top: 0,
          behavior: 'smooth', // This adds a smooth scroll effect
        })}}>
                <div>
                    <img loading="lazy" src={image}  className="lg:w-[404px] lg:h-[280px] rounded-md hover:shadow-xl"/>
                </div>
                <div className="py-4">
                    <p><b className=" text-[22px]">{poi.title}, </b>{poi.state}</p>
                    <p className="mt-2 text-[17px] ">{truncateText(shortDescription, 130)}</p>
                </div>
            </div>
            :
            <div key={key} className="lg:w-[404px]  max-sm:w-[268px] flex-none mb-5 cursor-pointer" onClick={()=>{navigate(`/en/place/${city2}/${url2}`); window.scrollTo({
              top: 0,
              behavior: 'smooth', // This adds a smooth scroll effect
            })}}>
                    <div>
                        <img loading="lazy" src={image}  className="lg:w-[404px] lg:h-[280px] rounded-md hover:shadow-xl"/>
                    </div>
                    <div className="py-4">
                        <p><b className=" text-[22px]">{poi.title}, </b>{poi.state}</p>
                        <p className="mt-2 text-[17px] ">{truncateText(poi.shortDescription, 130)}</p>
                    </div>
                </div>
            }
        {/* </NavLink> */}
        </>
     );
}

export default AttractionCard;