import React from "react";
import BlogDropdown from "../../Components/Blogs";
import SearchIcon from '@mui/icons-material/Search';
import PoiCard from "../../Components/Poi/poiCard";

function AllPoi() {
    const cards = ["1", "2", "3", "4"]

    return (
        <>
            <div>
                <div className="ml-40 mt-8 flex">
                    Home {" > "} Destinations
                </div>
                <div className="flex ml-40 mt-20">
                    <div className="w-[25%]">
                        <h2 className="font-bold text-[20px] mt-2">Destinations</h2>
                        <BlogDropdown />
                    </div>
                    <div className="w-[65%]">
                        <div className="flex justify-between">
                            <div className="font-semibold text-[18px] mt-1 text-gray-400">
                                1,891 results
                            </div>
                            <div>
                                <div className="relative">
                                    <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                    <input type='text' className='outline-none rounded-lg py-1 pl-3 pr-10' placeholder='Search destination...' style={{ border: `1px solid black` }} />
                                </div>
                            </div>
                        </div>

                        <div>
                            {cards.map(i => (
                                <PoiCard />
                            ))}
                        </div>





                    </div>
                </div>

            </div>
        </>
    );
}

export default AllPoi;