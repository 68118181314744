import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie"
function Description({description}) {
  const [isExpanded, setIsExpanded] = useState(false);
  // const [description,setDescription]=useState("")
  const [ar_description,setar_description]=useState("")
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const lang= Cookies.get("lang") || ""
useEffect(()=>{
  // setDescription(props.description)
  // setar_description(description.ar_description)
},[])
function createMarkup() {
  return {__html: description};
}
  return (
    <>
    <div className="relative max-w-full " key={description}>
      <div className={`overflow-hidden   ${isExpanded ? 'max-h-full' : 'max-h-40'}`}>
        <p style={{fontSize:"20px"}} className="leading-8" >
          {description}
        </p>
      </div>
      {!isExpanded && (
        <div className="absolute bottom-0 left-0 right-0 h-10 bg-gradient-to-t from-white via-white to-transparent pointer-events-none"></div>
      )}
     
    </div>
    <div className='flex justify-center'>
     <button 
     onClick={toggleExpand} 
     className=" text-[#5B27D5] font-bold "
   >
     {isExpanded ? 'Read Less' : 'Read More'}
   </button>
   </div>
   </>
  );
}

export default Description;
