import React from "react";
import { useEffect, useState } from "react";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
function ImportantTips(props) {
    const [tips,setTips]=useState([])
    useEffect(()=>{
        setTips(props?.tips)
    },[props])
    return ( 
        <>
    <div className="lg:w-[300px] lg:h-[175px] max-sm:w-[209px] cursor-pointer px-2 lg:py-5 max-sm:py-5 lg:pb-20 rounded-xl flex-none space-y-5 custom-shadow">
  <p className="text-lg font-medium"><EmojiEventsIcon/> {tips?.title}</p>
  <p className="text-[16px] text-[#77808B] font-medium">{tips?.description}</p>
</div>


       
        </>
     );
}

export default ImportantTips;