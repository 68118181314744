import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from "react";
import blogImage from "../../public/images/latestBlog.png"
function LatestBlogCard() {
    return ( 
        <>
        <div className="lg:w-[28%] max-sm:w-[60%] flex-none mb-5 mt-5 bg-white pb-5 rounded-xl shadow-lg">
            <div>
                <img src={blogImage}/>
            </div>
            <div className="ms-2 mt-1">
             <p className="font-bold text-xl">Best things to Try</p>
             <p className="mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
             <Link className="text-[#5B27D5] font-bold mt-1">Read Article <KeyboardArrowRightIcon/></Link>
            </div>
        </div>
        </>

     );
}

export default LatestBlogCard;