import React from "react";

function PoiCard() {
    return ( 
        <>
          <div className=" flex justify-between">
                        <div className="mt-7 w-[420px] h-[400px] rounded-xl shadow-lg">
                            <img style={{ width: "420px", height: "250px", borderRadius: "10px 10px 0 0" }} src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1a/7a/c6/5e/caption.jpg?w=1200&h=-1&s=1" />
                            <div className=" mt-3 ml-2 w-[70%]">
                                <div className="flex ">
                                  <h2 className="font-semibold">Mehrangarh , </h2>
                                  <p className="text-[14px] mt-0.5"> Jodhpur</p>
                                </div>
                                <div className="">
                                    <p  className="text-start  leading-4 w-[400px] text-[15px]  mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.....</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-7 w-[420px] h-[400px] rounded-xl shadow-lg">
                            <img style={{ width: "420px", height: "250px", borderRadius: "10px 10px 0 0" }} src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1a/7a/c6/5e/caption.jpg?w=1200&h=-1&s=1" />
                            <div className=" mt-3 ml-2 w-[70%]">
                                <div className="flex ">
                                  <h2 className="font-semibold">Mehrangarh , </h2>
                                  <p className="text-[14px] mt-0.5"> Jodhpur</p>
                                </div>
                                <div className="">
                                    <p  className="text-start  leading-4 w-[400px] text-[15px]  mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.....</p>
                                </div>
                            </div>
                        </div>
                       
                        </div>
        </>
     );
}

export default PoiCard;