import React, { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Button, Box, Typography } from "@mui/material";
import BlogDropdown from "../../Components/Blogs";

function AllBlogs() {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 11;

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const tags = [
        'Hill Stations',
        'Religious',
        'Global',
        'Japanese',
        'Europe',
        'Best Hotels',
        'Beaches',
        'Global'
    ];

    return (
        <>
            <div className="px-2">
                <div>
                    <div className="lg:ml-40 mt-8">
                        <p></p>Home{">"} Blogs
                    </div>
                </div>
                <div className="lg:flex lg:ml-40 lg:mt-20 max-sm:mt-5">
                    <div className="lg:w-[25%] max-sm:[100%]">
                        <h2 className="font-bold text-[20px] mt-2">Blogs</h2>
                        <BlogDropdown />
                        <div className="flex overflow-x-scroll max-sm:hidden">
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px',marginTop:"30px" }}>
                                    Popular:
                                </Typography>
                                <Box sx={{ display: 'flex',overscrollBehavior:"contain",  flexWrap: 'wrap', gap: '14px' }}>
                                    {tags.map((tag, index) => (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                borderRadius: '20px',
                                                padding: '6px 10px',
                                                textTransform: 'none',
                                                fontSize: '13px',
                                                borderColor: '#ddd',
                                                color: '#333',
                                                flex:"none"
                                            }}
                                        >
                                            {tag}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </div>
                        <div className="  lg:hidden">
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px',marginTop:"30px" }}>
                                    Popular:
                                </Typography>
                                <Box sx={{ display: 'flex',overscrollBehavior:"contain", gap: '14px',overflowX:"scroll" }}>
                                    {tags.map((tag, index) => (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                borderRadius: '20px',
                                                padding: '6px 10px',
                                                textTransform: 'none',
                                                fontSize: '13px',
                                                borderColor: '#ddd',
                                                color: '#333',
                                                flex:"none"
                                            }}
                                        >
                                            {tag}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </div>
                        <div className="max-sm:hidden">
                            <h1 className="font-semibold mt-10">Trending Blogs <ArrowForwardOutlinedIcon /></h1>
                            <div className="flex gap-2 mt-6 w-[70%]">
                                <img src="https://www.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png?width=602&height=300&name=Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                            <div className="flex gap-2 mt-4 w-[70%]">
                                <img src="https://inrealitysolutions.com/wp-content/uploads/2023/02/blog.jpeg" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                            <div className="flex gap-2 mt-4 w-[70%]">
                                <img src="https://www.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png?width=602&height=300&name=Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                            <div className="flex gap-2 mt-4 w-[70%]">
                                <img src="https://neilpatel.com/wp-content/uploads/2017/09/blog-post-image-guide.jpg" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                        </div>
                        <div className="lg:w-[70%] mt-10 max-sm:hidden" style={{ borderBottom: "1px solid black" }}></div>
                        <div className="max-sm:hidden">
                            <div className="font-semibold mt-8">Popular destinations <ArrowForwardOutlinedIcon /></div>
                            <div className="mt-8">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Mehrangarh_Fort_sanhita.jpg" style={{ height: "190px", width: "200px", borderRadius: "15px" }} />
                                Jodhpur, Rajasthan
                            </div>
                            <div className="mt-4">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Mehrangarh_Fort_sanhita.jpg" style={{ height: "190px", width: "200px", borderRadius: "15px" }} />
                                Jodhpur, Rajasthan
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-[65%] max-sm:w-[100%] ">
                        <div className="flex justify-between">
                            <div className="font-semibold text-[18px] mt-1 text-gray-400">
                                1,891 results
                            </div>
                            <div>
                                <div className="relative">
                                    <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                    <input type='text' className='outline-none rounded-lg py-1 pl-3 lg:pr-10 ' placeholder='Search blog...' style={{ border: `1px solid black` }} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-7 w-full rounded-xl shadow-lg py-2">
                            <img style={{ width: "890px", height: "300px", borderRadius: "10px" }} src="https://static.vecteezy.com/system/resources/thumbnails/008/940/443/small_2x/beautiful-aerial-view-of-tallinn-old-town-medieval-city-in-northen-europe-photo.jpg" />
                            <div className="lg:flex">
                                <div className="mt-3 ml-3 w-[70%]">
                                    <p className=""><LocalOfferOutlinedIcon style={{ fontSize: "15px", color: "gray" }} /> Europe</p>
                                    <p className="text-[17px] font-semibold mt-5">Why some European cities want to keep tourists away</p>
                                    <p className="mt-3 text-gray-400 leading-4 pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.....</p>
                                </div>
                                <button className="lg:ml-36 max-sm:ml-5 text-gray-500 lg:mt-16 text-[15px]">Read More<ArrowForwardOutlinedIcon /></button>
                            </div>
                        </div>
                        <div className="mt-7 w-full rounded-xl shadow-lg py-2">
                            <img style={{ width: "890px", height: "300px", borderRadius: "10px" }} src="https://static.vecteezy.com/system/resources/thumbnails/008/940/443/small_2x/beautiful-aerial-view-of-tallinn-old-town-medieval-city-in-northen-europe-photo.jpg" />
                            <div className="lg:flex">
                                <div className="mt-3 ml-3 w-[70%]">
                                    <p className=""><LocalOfferOutlinedIcon style={{ fontSize: "15px", color: "gray" }} /> Europe</p>
                                    <p className="text-[17px] font-semibold mt-5">Why some European cities want to keep tourists away</p>
                                    <p className="mt-3 text-gray-400 leading-4 pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.....</p>
                                </div>
                                <button className="lg:ml-36 max-sm:ml-5 text-gray-500 lg:mt-16 text-[15px]">Read More<ArrowForwardOutlinedIcon /></button>
                            </div>
                        </div>
                        <div className="mt-7 w-full rounded-xl shadow-lg py-2">
                            <img style={{ width: "890px", height: "300px", borderRadius: "10px" }} src="https://static.vecteezy.com/system/resources/thumbnails/008/940/443/small_2x/beautiful-aerial-view-of-tallinn-old-town-medieval-city-in-northen-europe-photo.jpg" />
                            <div className="lg:flex">
                                <div className="mt-3 ml-3 w-[70%]">
                                    <p className=""><LocalOfferOutlinedIcon style={{ fontSize: "15px", color: "gray" }} /> Europe</p>
                                    <p className="text-[17px] font-semibold mt-5">Why some European cities want to keep tourists away</p>
                                    <p className="mt-3 text-gray-400 leading-4 pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.....</p>
                                </div>
                                <button className="lg:ml-36 max-sm:ml-5 text-gray-500 lg:mt-16 text-[15px]">Read More<ArrowForwardOutlinedIcon /></button>
                            </div>
                        </div>
                        <div className="mt-7 w-full rounded-xl shadow-lg py-2">
                            <img style={{ width: "890px", height: "300px", borderRadius: "10px" }} src="https://static.vecteezy.com/system/resources/thumbnails/008/940/443/small_2x/beautiful-aerial-view-of-tallinn-old-town-medieval-city-in-northen-europe-photo.jpg" />
                            <div className="lg:flex">
                                <div className="mt-3 ml-3 w-[70%]">
                                    <p className=""><LocalOfferOutlinedIcon style={{ fontSize: "15px", color: "gray" }} /> Europe</p>
                                    <p className="text-[17px] font-semibold mt-5">Why some European cities want to keep tourists away</p>
                                    <p className="mt-3 text-gray-400 leading-4 pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.....</p>
                                </div>
                                <button className="lg:ml-36 max-sm:ml-5 text-gray-500 lg:mt-16 text-[15px]">Read More<ArrowForwardOutlinedIcon /></button>
                            </div>
                        </div>
                        <h1 className="font-semibold mt-10">Trending Blogs <ArrowForwardOutlinedIcon /></h1>
                        <div className="lg:hidden flex overscroll-x-conatin overflow-x-scroll gap-5 bg-gradient-to-r from-yellow-50  to-yellow-50 py-2">
                           
                            <div className="flex gap-2 mt-4 w-[70%] rounded-lg flex-none border-2 border-gray-200 items-center px-2 shadow-lg">
                                <img src="https://www.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png?width=602&height=300&name=Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                            <div className="flex gap-2 mt-4 w-[70%] rounded-lg flex-none border-2 border-gray-200 items-center px-2 shadow-lg">
                                <img src="https://inrealitysolutions.com/wp-content/uploads/2023/02/blog.jpeg" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                            <div className="flex gap-2 mt-4 w-[70%] rounded-lg flex-none border-2 border-gray-200 items-center px-2 shadow-lg">
                                <img src="https://www.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png?width=602&height=300&name=Marketing%20Strategy%20%26%20Trends%20Report_v2%20%281%29.png" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                            <div className="flex gap-2 mt-4 w-[70%] rounded-lg flex-none border-2 border-gray-200 items-center px-2 shadow-lg">
                                <img src="https://neilpatel.com/wp-content/uploads/2017/09/blog-post-image-guide.jpg" style={{ height: "50px", width: "50px", borderRadius: "10px" }} />
                                <p className="mt-3 leading-4">Lorem , tempor ut labore et dolore magna aliqua.....</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center mt-20 space-x-3">
                    <button
                        onClick={handlePrevPage}
                        className="px-2 py-1 rounded hover:bg-gray-200"
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                    <span>
                        <span className="px-2 py-1 border border-gray-300 rounded">
                            {currentPage}
                        </span>{' '}
                        of {totalPages}
                    </span>
                    <button
                        onClick={handleNextPage}
                        className="px-2 py-1 rounded hover:bg-gray-200"
                        disabled={currentPage === totalPages}
                    >
                        &gt;
                    </button>
                </div>
            </div>
        </>
    );
}

export default AllBlogs;
