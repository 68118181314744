import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import Cookies from "js-cookie"
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../public/images/hopdayslogo.png"
function Footer() {
    const location =useLocation()
    const navigate =useNavigate()
    const [lang,setLang]=useState(Cookies.get('lang'))
    // const lang = Cookies.get('lang') || '';
    useEffect(()=>{
      if(location.pathname.includes("en") && Cookies.get('lang')==="ar"){
        Cookies.set('lang', "en", { expires: 7 })
        setLang("en")
      }if(location.pathname.includes("ar") && Cookies.get('lang')==="en"){
        Cookies.set('lang', "ar", { expires: 7 })
        setLang("ar")
      }
    },[location])
    return ( 
        <>
        <Divider className="bg-black " style={{marginTop:"100px"}}/>
        <div className="mt-10 max-sm:hidden">
          <div className="lg:flex justify-between">
            <div className="lg:ms-20  w-[40%]">
                <div>
                    <img src={logo} className="lg:w-1/2"/>
                </div>
                <hr/>
                <div className="flex gap-9 ms-0 text-[#5B27D5] mt-5">
                <InstagramIcon/>
                <LinkedInIcon/>
                <XIcon/>
                <FacebookIcon/>
                </div>
            </div>
            {lang==="ar"?
            <div className="ms-64 mt-5 w-[30%]">
                <p className="font-bold text-xl text-[#5B27D5]">روابط هامة</p>
                <ul className="space-y-3 mt-2">
                <li>الأماكن</li>
                <li>معلومات عنا</li>
                <li>المدونات</li>
                </ul>
            </div>
            :
            <div className="ms-64 mt-5  w-[30%]">
            <p className="font-bold text-xl text-[#5B27D5]">Important Links</p>
            <ul className="space-y-3 mt-2">
            <li>Destinations</li>
                    <li>About Us</li>
                    <li>Blogs</li>
            </ul>
        </div>
}
            {lang==="ar"?
            <div className="ms-10 mt-5 w-[30%] ">
                <p className="font-bold text-xl text-[#5B27D5]">أصبح عضوا</p>
               <p className="mt-2">انضم إلى مجتمع HopDays للمسافرين</p>
               <button className="px-4 rounded-full bg-[#9747FF] text-white py-1 mt-4">إنشاء حساب</button>
            </div>
            :
            <div className="ms-10 mt-5 w-[30%] ">
            <p className="font-bold text-xl text-[#5B27D5]">Become A Member</p>
           <p className="mt-2">Join the HopDays community of travelers</p>
           <button className="px-4 rounded-full bg-[#9747FF] text-white py-1 mt-4">Create Account</button>
        </div>
}
          </div>
          {lang==="ar"?
          <div className="bg-gradient-to-r from-yellow-50  to-yellow-50 flex flex-row-reverse w-full px-20 py-5 mt-5 gap-20 ">
                  <p>2024 هوب دايز. كل الحقوق محفوظة. لا يجوز إعادة إنتاج أي جزء من هذا الموقع دون الحصول على إذن كتابي منا.</p>
                <select className="me-40 rounded-full px-10 py-1" 
                 onChange={(e) => {
                  Cookies.set('lang', e.target.value, { expires: 7 });
                  setLang(e.target.value)
                  if(Cookies.get('lang')==="ar"){
                  const newUrl=  location.pathname.replace("/en/","/ar/")
                  console.log(newUrl)
                    navigate(newUrl)
                
                    // window.location.reload()
                  }if(Cookies.get('lang')==="en"){
                  const newUrl=  location.pathname.replace("/ar/","/en/")
                  console.log(newUrl)
                   navigate(newUrl)
                   
                  }
                }}
                  value={lang}>
                    <option value="en">English</option>
                    <option value="ar">Arabic</option>
                </select>
          </div>
          :
          <div className="bg-gradient-to-r from-yellow-50  to-yellow-50 flex w-full px-20 py-5 mt-5 gap-20 ">
          <p>© 2024 HopDays. All rights reserved. No part of this site may be reproduced without our written permission.</p>
        <select className="ms-40 rounded-full px-10 py-1" 
        onChange={(e) => {
          Cookies.set('lang', e.target.value, { expires: 7 });
          setLang(e.target.value)
        if(Cookies.get('lang')==="ar"){
        const newUrl=  location.pathname.replace("/en/","/ar/")
       
        console.log(newUrl)
       
          navigate(newUrl)
          
          // window.location.reload()
        }if(Cookies.get('lang')==="en"){
       
          const newUrl=  location.pathname.replace("/ar/","/en/")
   
        console.log(newUrl)
         navigate(newUrl)
        
        }
         
        }}
        value={lang}>
            <option value="">English</option>
            <option value="ar">Arabic</option>
        </select>
  </div>
}
        </div>


        <div className="lg:hidden">
          <div>
            <div className="w-[100%] flex justify-center">
                <img src="../hopdayslogo.png" className="w-[50%]"/>
            </div>
        
            <div className="flex text-[#5B27D5] justify-center gap-4 mt-2">
            <InstagramIcon/>
                <LinkedInIcon/>
                <XIcon/>
                <FacebookIcon/>
            </div>
          </div>
          <div className="flex gap-5 ms-5 mt-10 justify-center">
            <div className="w-[45%] ">
                <p className="font-bold  text-[#5B27D5]">Important Links</p>
                <ul className="space-y-1 mt-2">
                    <li>Destinations</li>
                    <li>About Us</li>
                    <li>Blogs</li>
                </ul>
            </div>
            <div className="" style={{borderLeft:"1px solid black"}}></div>
            <div className="w-[50%]">
                <p className="font-bold text-[#5B27D5]">Become A Member</p>
               <p className="mt-2">Join the HopDays community of travelers</p>
               <button className="px-4 rounded-full bg-[#9747FF] text-white py-1 mt-4">Create Account</button>
            </div>
          </div>
          <div className="bg-gradient-to-r from-yellow-50  to-yellow-50 text-center w-full px-5 py-5 mt-5 gap-20 ">
          <select className=" rounded-full  px-20 py-1" onChange={(e)=>{Cookies.set("lang",e.target.value,{expires:7}); window.location.reload()}} value={lang}>
                    <option value="en">English</option>
                    <option value="ar">Arabic</option>
                </select>
                <p className="mt-3">© 2024 HopDays. All rights reserved. No part of this site may be reproduced without our written permission.</p>
          </div>
        </div>
        </>
     );
}

export default Footer;