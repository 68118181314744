import React from "react";
import PlaceIcon from '@mui/icons-material/Place';
import Cookies from "js-cookie"

function WholeDestination(props) {
    let lang =Cookies.get("lang") || ""
    return ( 
        <>
        <div key={props.key} className="max-sm:w-[40%] lg:w-[31%]  rounded-md text-center cursor-pointer flex-none" id='popular-destination-card'>
            <div className='' id='popular-destination-card-image'>
            <img src="https://media.istockphoto.com/id/639128162/vector/rajasthan-blue-map-on-degraded-background-vector.jpg?s=612x612&w=0&k=20&c=RzKzGJrQeuCe66VJ_RFm_tb_1sviutIBFonlhKHteNg=" className=" rounded-xl lg:w-[400px] lg:h-[350px] max-sm:w-[120px] max-sm:h-[158px]  " />
            </div>
            {lang==="ar" ? 
            <div className='relative text-centrer bottom-[80px]' id='popular-destination-card-text'>
            <p className="text-white font-bold max-sm:text-xl lg:text-2xl">Jodhpur</p>
            <p className="text-white">
                <PlaceIcon/> Rajasthan
            </p>
            </div>
          :
          <div className='relative text-centrer bottom-[80px]' id='popular-destination-card-text'>
            <p className="text-white font-bold max-sm:text-xl lg:text-2xl">Jodhpur</p>
            <p className="text-white">
                <PlaceIcon/> Rajasthan
            </p>
            </div>
}
        </div>
        </>
     );
}

export default WholeDestination;